<template>
  <div>
    <div class="auth-wrapper auth-wrapper-login auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Login v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <b-img
              :src="logoImg"
              alt="Payrite"
              style="width:150px"
            />
          </b-link>
          <b-card-text class="mb-2 text-center">
            Log in met admin token!!
          </b-card-text>
          <!-- form -->
          <b-card-text class="text-center mt-2">
            <b-spinner show="!showLoginError"></b-spinner>
            <b-alert
              :show="showLoginError"
              v-height-fade.appear
              variant="danger"
              class="mb-1"
            >
              <div class="alert-body mt-1">
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                Token is niet meer geldig!!!
              </div>
            </b-alert>
          </b-card-text>
        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>
    <template>
      <p class="clearfix mb-0 login-footer">
      </p>
    </template>
  </div>
</template>

<script>
import {
  BImg, BSpinner, VBTooltip, BCard, BLink, BCardText, BAlert,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { heightFade } from '@core/directives/animations'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    // BSV
    BImg,
    BSpinner,
    BCard,
    BLink,
    BCardText,
    BAlert,
  },
  mixins: [togglePasswordVisibility],
  directives: {
    'b-tooltip': VBTooltip,
    'height-fade': heightFade,
  },
  data() {
    return {
      /* eslint-disable global-require */
      logoImg: require('@/assets/images/logo/payrite.png'),
      showLoginError: false,
    }
  },
  mounted() {
    this.login()
  },
  methods: {
    login() {
      this.showLoginError = false

      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      useJwt.login({
        adminToken: this.$route.query.token,
      })
        .then(response => {
          useJwt.setToken(response.data.accessToken)
          useJwt.setRefreshToken(response.data.refreshToken)

          const { userData } = response.data
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$ability.update(userData.ability)

          this.$router.replace(getHomeRouteForLoggedInUser(userData.type))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome ${userData.fullName || userData.username}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'U bent succesvol ingelogd!',
                },
              })
            })
        })
        .catch(() => {
          this.showLoginError = true
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

.auth-wrapper-login{
  background: url("../../../assets/images/pages/inlog.jpg");
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

[dir] .auth-wrapper.auth-v1 .auth-inner:before {
  background-image: none !important;
}
</style>
